import Loader from "app/components/Loader";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { DirectusImage } from "app/core/common";
import { truncate } from "app/helpers/helper";
import { PlayCircleOutline } from "@mui/icons-material";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import { useMediaPlayer } from "app/context/mediaPlayerContext";
import Button from "app/components/button/button";
import { useHistory } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MoreMenu from "./moreMenu";

export default function AlbumView({
  albums,
  loading,
  handleAlbumSelect,
  handlePlayPauseAlbum,
  data,
}: {
  albums?: any;
  loading?: any;
  handleAlbumSelect?: any;
  handlePlayPauseAlbum?: any;
  data?: any;
}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showMenu, setShowMenu] = useState(false);
  const [playingAlbum, setPlayingAlbum] = useState(false);
  const history = useHistory();
  const { play, actualTrack } = useMediaPlayer();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleRedirecttoHome = () => {
    history.push("/");
  };

  return (
    <div className={styles.music_library_container}>
      <div className={styles.music_library_container__header}>
        <div className={styles.music_library_container__header_infos}>
          <div className={styles.music_library_container__header_infos_details}>
            <div
              className={
                styles.music_library_container__header_infos_details_text
              }
            >
              <h1>Music Library</h1>
              <p>
                {albums?.length === 1
                  ? "Album: " + data?.length
                  : "Albums: " + data?.length}
              </p>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(23, 25, 27, 0.48)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      ) : albums?.length > 0 ? (
        windowWidth > 767 ? (
          <div className={styles.musicTableContainer}>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Album</th>
                  <th>Name</th>
                  <th>Artist</th>
                  <th>Tracks</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {albums?.map((album, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td onClick={() => handleAlbumSelect(album)}>
                      <img
                        src={DirectusImage(album?.album?.artwork)}
                        alt=""
                        className={styles.tableImage}
                      />
                    </td>
                    <td onClick={() => handleAlbumSelect(album)}>
                      {album?.album?.name}
                    </td>
                    <td onClick={() => handleAlbumSelect(album)}>
                      {album?.album?.artist?.display_name
                        ? album?.album?.artist?.display_name
                        : album?.album?.artist?.username}
                    </td>
                    <td onClick={() => handleAlbumSelect(album)}>
                      {album?.album?.number_of_tracks}
                    </td>
                    <td onClick={() => handlePlayPauseAlbum(album)}>
                      {album?.album?.tracks.find(
                        (track) => track.id === actualTrack?.track?.id && play
                      ) ? (
                        <PauseCircleOutlineIcon className={styles.playIcon} />
                      ) : (
                        <PlayCircleOutline className={styles.playIcon} />
                      )}
                    </td>
                    <td onClick={() => setShowMenu((prev) => !prev)}>
                      <MoreHorizIcon className={styles.playIcon} />
                    </td>
                    {showMenu && <MoreMenu />}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <>
            <hr />
            {albums?.map((album, index) => (
              <div className={styles.music_list} key={index}>
                <div className={styles.music_list__item}>
                  <img src={DirectusImage(album?.album?.artwork)} alt="" />
                  <div className={styles.music_list__info}>
                    <h4>{truncate(album?.album?.name, 30, "...")}</h4>
                    <p>
                      {truncate(
                        album?.album?.artist?.display_name ??
                          album?.album?.artist?.username,
                        30,
                        "..."
                      )}
                    </p>
                  </div>
                </div>
                <div></div>
                {album?.album?.tracks.find(
                  (track) => track.id === actualTrack?.track?.id && play
                ) ? (
                  <PauseCircleOutlineIcon
                    className={styles.playIcon}
                    onClick={() => handlePlayPauseAlbum(album)}
                  />
                ) : (
                  <PlayCircleOutline
                    className={styles.playIcon}
                    onClick={() => handlePlayPauseAlbum(album)}
                  />
                )}
                <MoreHorizIcon className={styles.playIcon} />
              </div>
            ))}
          </>
        )
      ) : (
        <div className={styles.no_data}>
          <p>
            At this moment you don’t have albums to play, please visit
            <span onClick={() => handleRedirecttoHome()}> Discover </span>
            and start exploring the available Collections.
          </p>
          <Button size="sm" onClick={() => handleRedirecttoHome()}>
            Discover more
          </Button>
        </div>
      )}
    </div>
  );
}
