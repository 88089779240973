import { useEffect, useState } from "react";
import styles from "./index.module.scss";

import share from "assets/fans/share.svg";
//import { Trans } from "react-i18next";
import { useHook } from "app/hooks/common";
import CountdownComp from "./countdown";
import {
  CurrencyRateType,
  DirectusImage,
  LaunchPadNFT,
  NFTMinterQuery,
} from "app/core/common";
import Modal from "app/components/Modal";
import { Link } from "react-router-dom";
import Button from "app/components/button/button";
import LoginModal from "app/components/loginModal/LoginModal";
import { Trans } from "react-i18next";
import { useLogin } from "app/context/loginProvider";
import { textTruncate, truncate } from "app/helpers/helper";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import moment from "moment-timezone";
import axios from "axios";
import { tryCatch } from "ramda";
import { useNotification } from "app/context/notification";

const ClaimableForm = ({
  queryResult,
  launchpadNFT,
  currencyRate,
  launchpad_imageData,
  index,
  setPaymentModal,
  mintHandler,
  quantity,
  setQuantity,
}: {
  queryResult?: NFTMinterQuery;
  launchpadNFT?: LaunchPadNFT;
  currencyRate?: CurrencyRateType;
  launchpad_imageData?: any;
  index?: Number;
  setPaymentModal?: any;
  mintHandler?: any;
  quantity?: number;
  setQuantity?: any;
}) => {
  /* const { MintNft } = handleFansTransaction(); */
  const [errorCode, setErrorCode] = useState(0);
  const [errorMessage, setErrorMessage] = useState("Claim Now");
  const [continueCode, setContinueCode] = useState(false);
  const [usedCode, setUsedCode] = useState("");
  const [code, setCode] = useState("");
  const [type, setType] = useState<number>(1);
  const { toggleLoginModal, toggleSignupModal, toogleRegisterModal } =
    useLogin();
  const { address, cookie, userInfo } = useHook();
  const { notificationEvent } = useNotification();

  const [price, symbol] = launchpadNFT?.launchpad_type[
    Number(index)
  ]?.launchInfo?.mintPrice?.split(" ") || ["", "USDC"];
  const maxSupply =
    launchpadNFT?.launchpad_type[Number(index)]?.launchInfo?.maxSupply;
  /* const { balance } = useJunoTokenBalance(symbol); */

  const contractAddr =
    launchpadNFT?.launchpad_type[Number(index)]?.launchInfo?.NFT;

  const currentTime = moment.utc().valueOf();
  const genTime = (time: string) =>
    moment(time).add(moment(time).utcOffset(), "minutes").valueOf();

  const mintStarted = genTime(
    moment
      .utc()
      .format(
        `${launchpadNFT?.launchpad_type[Number(index)].launchInfo.startDate} ${
          launchpadNFT?.launchpad_type[Number(index)].launchInfo.startTime
        }`
      )
  );
  const mintEnded = genTime(
    moment
      .utc()
      .format(
        `${launchpadNFT?.launchpad_type[Number(index)].launchInfo.endDate} ${
          launchpadNFT?.launchpad_type[Number(index)].launchInfo.endTime
        }`
      )
  );

  const beforeStartTime = currentTime < mintStarted;

  /* const [mintCount, setMintCount] = useState(1); */

  const EndDate = () => {
    return (
      <p
        className={styles.endDate}
        style={{ textAlign: "center", paddingTop: "10px" }}
      >
        <Trans>endDate</Trans>:{" "}
        <b>
          <CountdownComp launchpadNFT={launchpadNFT} index={Number(index)} />
          {/* {new Date(
            launchpadNFT?.launchpad_type[Number(index)]?.launchInfo.endDate
          ).getUTCDate()}{" "}
          {
            monthNames[
              new Date(
                launchpadNFT?.launchpad_type[Number(index)]?.launchInfo.endDate
              ).getUTCMonth()
            ]
          }{" "}
          {new Date(
            launchpadNFT?.launchpad_type[Number(index)]?.launchInfo.endDate
          ).getUTCFullYear()} */}
        </b>
      </p>
    );
  };
  // function to increment the quantity of NFTs to mint
  const handleIncrement = () => {
    setQuantity((prevQuantity: number) => prevQuantity + 1);
  };
  // function to decrease the quantity of NFTs to mint
  const handleDecrement = () => {
    setQuantity((prevQuantity: number) =>
      prevQuantity > 1 ? prevQuantity - 1 : 1
    );
  };

  const handleSetCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "code") {
      setCode(value);
    }
  };

  useEffect(() => {
    const validateCode = () => {
      if (code) {
        setContinueCode(true);
        setErrorCode(0);
        setErrorMessage("Claim Now");
      }
    };

    validateCode(); // Call the validation function
  }, [code]); // Trigger on changes to `code` or `trueCode`

  const codeValidator = async () => {
    // Trigger "in progress" notification (subType 1)
    notificationEvent({ subType: 1 });

    const queryData = {
      cookie: cookie,
      collection_addr: contractAddr,
      recipient: userInfo.wallet_address,
      type_name:
        launchpadNFT?.launchpad_type[Number(type) - 1]?.fan_collection?.name,
    };

    const url = `${process.env.REACT_APP_MIDDLEWARE}minter/claimNft`;
    try {
      // Make the API request
      await axios({
        url,
        method: "post",
        data: {
          code: code,
          query: queryData,
        },
      }).then((res: any) => {
        if (res.status === 200) {
          // Trigger success notification (subType 2)
          notificationEvent({
            subType: 2,
            transaction_type: "claim",
            txHash: res.data.tx_hash,
            redirectURL: `/user/${userInfo?.username ?? userInfo?.id}?tab=nfts`, // user profile show collectibles
          });
          return;
        }
      }).catch(err => {
        const {message, code} = err.response.data;
        console.log({err: message});
        //return error
        notificationEvent({
          subType: undefined,
        });
        setContinueCode(false);
        setErrorMessage(message);
        setErrorCode(code);
        return;
      });
    } catch (error) {
      notificationEvent({ subType: undefined });
      setContinueCode(false);
      const errorMessage =
        error?.response?.data?.message || "An unexpected error occurred.";
      setErrorMessage(errorMessage);
      const errorCode =
        error?.response?.data?.code || "An unexpected error occurred.";
      setErrorCode(errorCode);
    }
  };

  return (
    <div>
      <>
        <div className={styles.project_social}>
          {/* <ShareButton name={launchpadNFT?.project_name} icon={share} /> */}
        </div>

        <div className={styles.nft_view}>
          <div className={styles.header}>
            <div className={styles.desc}>
              <b
                className={styles.title}
                dangerouslySetInnerHTML={{
                  __html:
                    launchpadNFT?.launchpad_type[Number(index)]?.fan_collection
                      .name,
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    launchpadNFT?.launchpad_type[Number(index)]?.fan_collection
                      .description,
                }}
              />
            </div>

            {/* Creator Info Section */}
            <div className={styles.artist}>
              <Link
                to={`/user/${
                  launchpadNFT?.artist.username ?? launchpadNFT?.artist.id
                }`}
                className={styles.profile}
              >
                <img
                  src={DirectusImage(launchpadNFT?.artist?.avatar?.id)}
                  alt="thumb"
                />
                <div>
                  <span>
                    <>CREATOR</>
                  </span>
                  <p>
                    {textTruncate(
                      launchpadNFT?.artist.display_name ??
                        launchpadNFT?.artist.first_name,
                      12
                    )}
                  </p>
                </div>
              </Link>
            </div>

            {/* Creator Info Section */}
            <div className={styles.artist}>
              <div>
                <span>
                  <>Collection Type</>
                </span>
                <p style={{ textTransform: "capitalize" }}>
                  <>{launchpadNFT?.collection_type}</>
                </p>
              </div>
              <div className={styles.divider} />
              <div>
                <span>
                  <>Total Quantity</>
                </span>
                <p style={{ textTransform: "capitalize" }}>
                  <>{maxSupply - 10}</>
                </p>
              </div>
            </div>

            {/* End Date Section */}
            {/* <div className={`${styles.mint_live} ${styles.mint_live_border}`}>
              <button className={styles.mint_countdown}>
                {launchpadNFT?.launch_tba ? (
                  <span className={styles.soon}>
                    <>Coming Soon</>
                  </span>
                ) : (
                  <span>
                    <CountdownComp
                      launchpadNFT={launchpadNFT}
                      index={Number(index)}
                    />
                  </span>
                )}
              </button>
            </div> */}

            {/*code verification input*/}
            <div className={styles.verification}>
              <input
                maxLength={14}
                placeholder="Enter Claim code"
                name="code"
                type="text"
                onChange={(e) => handleSetCode(e)}
              />
            </div>

            {/* NFT Mint Section */}
            {!beforeStartTime ? (
              <>
                <div className={`${styles.mint_live}`}>
                  {mintEnded < currentTime ? (
                    <button className={styles.buy} disabled>
                      Mint Ended
                    </button>
                  ) : (
                    <>
                      {!address ? (
                        <>
                          <Button
                            onClick={() => {
                              toggleLoginModal();
                              toogleRegisterModal(true);
                            }}
                          >
                            Login
                          </Button>
                        </>
                      ) : (
                        <>
                          {/* <div className={styles.quantityContainer}>
                            <button
                              onClick={handleIncrement}
                              className={styles.incrementButton}
                            >
                              <AddIcon sx={{ color: blue[500] }} />
                            </button>

                            <span className={styles.quantity}>{quantity}</span>
                            <button
                              onClick={handleDecrement}
                              className={styles.decrementButton}
                            >
                              <RemoveIcon sx={{ color: blue[500] }} />
                            </button>
                          </div> */}
                          <button
                            disabled={
                              !address ||
                              launchpadNFT?.mint_status !== "active" ||
                              !continueCode
                            }
                            onClick={() => codeValidator()}
                            className={styles.buy}
                            style={{
                              backgroundColor:
                                errorCode === 0 && continueCode
                                  ? "#00aaff"
                                  : errorCode === 0 && !continueCode
                                  ? "#808080"
                                  : errorCode === 2 && !continueCode
                                  ? "#9b83f9"
                                  : "#ff0000",
                            }}
                          >
                            {launchpadNFT?.mint_status !== "active" ? (
                              <>Paused</>
                            ) : (
                              <>{errorMessage}</>
                            )}
                          </button>
                        </>
                      )}
                    </>
                  )}
                </div>
                {/* <EndDate /> */}
              </>
            ) : (
              <div className={styles.mint_live}>
                <button disabled className={styles.buy}>
                  <span className={styles.soon}>
                    <>Starting Soon</>
                  </span>
                </button>
              </div>
            )}

            {/* Warn user that NFT will be random */}
            {launchpadNFT?.launchpad_type[Number(index)]?.type_gallery?.length >
              1 && (
              <p style={{ textAlign: "center", color: "#677599" }}>
                <Trans>mintNote</Trans>
              </p>
            )}
          </div>
        </div>
      </>
    </div>
  );
};

export default ClaimableForm;
