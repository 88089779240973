import React from "react";
import styles from "./styles.module.scss";

export default function MoreMenu() {
  return (
    <div className={styles.menu}>
      <span>Add to Queue</span>
      <span>See Artist</span>
    </div>
  );
}
